<template>
  <div v-if="permission.sales_over_view_access">
    <b-row class="d-flex justify-content-between">
      <b-col
          cols="12"
          md="4"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>Show</label>
        <v-select
            @on-change="paginateChange"
            :options="perPageOptions"
            v-model="search.paginate"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
        />
        <label>entries</label>
      </b-col>
      <!-- Search -->
      <b-col
          cols="12"
          md="4">
        <div class="mb-1 d-flex align-items-center justify-content-end">
          <b-form-input
              v-model="search.search_data"
              class="d-inline-block"
              placeholder="Search..."
          />
        </div>
      </b-col>
    </b-row>
    <b-card no-body>
      <b-card-body class="pb-0">

        <b-row class="d-flex justify-content-end mb-1">
          <!-- Purchase From Date -->
          <b-col
              cols="12"
              md="4">
            <div class="d-flex align-items-center justify-content-end">
              <DatePicker
                  format="yyyy-MM-dd"
                  type="date"
                  name="start_from_date"
                  v-model="search.from_date"
                  id="start_from_date"
                  placeholder="Start Date From"
                  :clearable="true"
                  style="width: 100%"
              ></DatePicker>
            </div>
          </b-col>

          <!-- Purchase to Date -->
          <b-col
              cols="12"
              md="4">
            <div class="d-flex align-items-center justify-content-end">
              <DatePicker
                  format="yyyy-MM-dd"
                  type="date"
                  name="start_to_date"
                  v-model="search.to_date"
                  id="start_to_date"
                  placeholder="Start Date To"
                  :clearable="true"
                  style="width: 100%"
              ></DatePicker>
            </div>
          </b-col>

          <!-- Teacher -->
          <b-col
              cols="12"
              md="4">
            <div class="d-flex align-items-center justify-content-end">
              <Select
                  v-model="search.teacher_id"
                  filterable
                  @on-change="SearchData"
                  :clearable="true"
                  name="teacher_id"
                  id="teacher_id"
                  placeholder="Select Teacher"
              >
              <Option
                  :value="teacher.id"
                  v-for="(teacher, index) in teachers"
                  :key="index"
                  v-if="teachers.length"
              >{{ teacher.name }}
              </Option
              >
              </Select>
            </div>
          </b-col>
        </b-row>

      </b-card-body>
      <div class="table-responsive">
        <table class="table">
          <thead>
          <tr>
            <th class="align-middle text-center">Sl</th>
            <th>Name</th>
            <th class="text-center align-middle">Total Sales</th>
            <th class="text-center align-middle">Paid Sales</th>
            <th class="text-center align-middle">Total Payment</th>
            <th class="text-center align-middle">Total Commission</th>
          </tr>
          </thead>
          <tbody>
          <tr
              v-for="(course, index) in courses.data"
              :key="index"
              v-if="courses.data.length"
          >
            <td class="align-middle text-center">
              {{ courses.from + index }}
            </td>
            <td>
              {{ course.name }}
            </td>
            <td class="align-middle text-center">
              {{ course.total_purchase || 0 }}
            </td>
            <td class="align-middle text-center">
              {{ course.total_paid_purchase || 0 }}
            </td>
            <td class="align-middle text-center">
              {{ course.total_payment || 0 }}/-
            </td>
            <td class="align-middle text-center">
              {{ course.total_commission || 0 }}/-
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
            >Showing {{ courses.from ? courses.from : 0 }} to
              {{ courses.to ? courses.to : 0 }} of
              {{ courses.total ? courses.total : 0 }}
              entries</span
            >
          </b-col>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <pagination
                :data="courses"
                @pagination-change-page="getResults"
                align="right"
                :show-disabled="true"
                :limit="5"
            ></pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BCardText,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody, BFormInput,
} from "bootstrap-vue";
import _ from "lodash";
import {ValidationProvider} from "vee-validate";
import vSelect from "vue-select";

export default {
  data() {
    return {
      search: {
        search_data: "",
        from_date: "",
        to_date: "",
        teacher_id: null,
        paginate: 10,
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100],
      total_commission: 0,
      courses: {},
    };
  },
  mounted() {
    this.getResults();
    this.$store.dispatch("getTeacher");
  },
  methods: {
    // Get All Role Method
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      this.$Loading.start();
      const courses = await this.callApi(
          "post",
          "/app/report/course/sales/over/view?page=" + this.search.page,
          this.search
      );
      if (courses.status == 200) {
        this.courses = courses.data.courses;
        this.$Loading.finish();
      }
    },
    SearchData: _.debounce(function () {
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },
  },
  components: {
    vSelect,
    ValidationProvider,
    BFormInput,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },

  computed: {
    permission() {
      return this.$store.getters.getPermissions.report;
    },
    teachers() {
      return this.$store.getters.getTeacher;
    },
  },

  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.from_date": function (val) {
      this.SearchData();
    },
    "search.to_date": function (val) {
      this.SearchData();
    },
    "search.teacher_id": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
};
</script>

<style></style>
